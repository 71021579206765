import React from 'react';
import './about.css';
import ME from '../../assets/moi-about-11.png';
import {FaAward} from 'react-icons/fa';
import {FiUsers} from 'react-icons/fi';
import {VscFolderLibrary} from 'react-icons/vsc';

const About = () => {
  return (
    <section id='about'>
      <h5>Apprendre à me connaitre</h5>
      <h2>À propos de moi</h2>

      <div className='container about__container'>
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt='About image'/>
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Expérience</h5>
              <small>Formation développeur web et mobile fullstack</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Formation</h5>
              <small>Doranco</small>
              <br></br>
              <small>La Capsule</small>
              <br></br>
              <small>Greta Python</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projets</h5>
              <small>10+ Complétés</small>
            </article>
          </div>

          <p style={{ textIndent: '20px' }}>Après 14 années dans le commerce dont la moitié à gérer ma boutique d'achat et revente de jeux vidéo en tant que franchisé GameCash, j'ai pris la décision de me réorienter vers le développement Web. D'abord formé par l'organisme <a href="https://www.doranco.fr/" className='about__formation' target='_blank'>Doranco</a>, j'ai voulu me perfectionner en suivant une autre formation avec le Bootcamp <a href="https://www.lacapsule.academy/?kw=la%20capsule&cpn=18012104777&source=adwords&campaign=brand&k=la%20capsule&matchtype=e&ads=615960534476&device=c&utm_term=la%20capsule&utm_campaign=Brand&utm_source=adwords&utm_medium=ppc&hsa_acc=5017504832&hsa_cam=18012104777&hsa_grp=141422185353&hsa_ad=615960534476&hsa_src=g&hsa_tgt=kwd-315066778716&hsa_kw=la%20capsule&hsa_mt=e&hsa_net=adwords&hsa_ver=3&gclid=Cj0KCQjwhL6pBhDjARIsAGx8D59W7BjsL6hdPNPaohk0TntRMF5fm7DdjqjHCF7Pg7ZzLPH1L-lqU9IaAsidEALw_wcB" className='about__formation' target='_blank'>La Capsule</a> où j'ai pu me former au langage de programmation JavaScript, côté Frontend comme côté Backend, à l'aide de divers projets. Aujourd'hui je recherche un premier emploi, une alternance ou un stage afin de mettre mes compétences au service d'une entreprise.</p>
        
          <a href='#contact' className='btn btn-primary'>Echangeons</a>
        </div>
      </div>
    </section>
  )
}

export default About