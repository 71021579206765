import React from 'react';
import {BsLinkedin} from 'react-icons/bs';
import {FaGithub} from 'react-icons/fa';

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href='https://www.linkedin.com/in/laurent-boucid-00282126b/' target='_blank'><BsLinkedin /></a>
        <a href='https://github.com/LaurentBoucid' target='_blank'><FaGithub /></a>
    </div>
  )
}

export default HeaderSocials