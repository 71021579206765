import React from 'react';
import './contact.css';
import { MdOutlineEmail } from 'react-icons/md';
import { RiMessengerLine } from 'react-icons/ri';
import { BsWhatsapp } from 'react-icons/bs';
import { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_b2n8dme', 'template_reugjnm', form.current, 'pMTIGvw_mC_k3xdgw')

    e.target.reset();
  }
  return (
    <section id='contact'>
      <h5>Entrer en contact</h5>
      <h2>Me Contacter</h2>

      <div className='container contact__container'>
        <div className='contact__options'>
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>laurent.boucid@hotmail.fr</h5>
            <a href="mailto:laurent.boucid@hotmail.fr" target='_blank'>Envoyer un message</a>
          </article>
          <article className="contact__option">
            <RiMessengerLine className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>Laurent BOUCID</h5>
            <a href="https://m.me/laurent.boucid.7" target='_blank'>Envoyer un message</a>
          </article>
          <article className="contact__option">
            <BsWhatsapp className='contact__option-icon'/>
            <h4>WhatsApp</h4>
            <h5>06 72 07 88 79</h5>
            <a href="https://api.whatsapp.com/send?phone=33672078879" target='_blank'>Envoyer un message</a>
          </article>
        </div>
        
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Votre nom' required />
          <input type="text" name='email' placeholder='Votre email' required />
          <textarea name="message" rows="7" placeholder='Votre message' required ></textarea>
          <button type='submit' className='btn btn-primary'>Envoyer un message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact